import React from "react";

import BuildersPdf from "../../Assets/PDF/Real-Estate-Investors.pdf";
import Button from "../../utils/Button";

import "./style.scss";
import "../HomeSchoolerScreen/style.scss";

const RealEstatePage = () => {
  return (
    <div className="main_container">
      <h2 className="main_heading">Revolutionize Education: Homeschool Community Initiative Seeks Visionary Real Estate Investor</h2>

      {/* Overview */}
      <div className="table_container">
        <h2 className="main_heading main_heading_no_margin">Project Overview:</h2>

        <p className="main_para">
          The Homeschool Community Initiative (HCI) is not just a project; it's a visionary journey aimed at reshaping the future of education. We stand at the threshold of a
          groundbreaking transformation in homeschooling, and we invite you to join us on this educational revolution.
        </p>

        {/* HCI Unique */}
        <h2 className="main_heading main_heading_no_margin">Why HCI is Unique:</h2>
        <p className="main_para">HCI is not your typical real estate venture. It's a movement to redefine education. Here's why HCI is unique:</p>

        <h4 className="sub_heading">
          Homeschool Districts:&nbsp;
          <span className="main_para">
            We're changing the landscape of homeschooling by creating homeschool districts within neighborhoods. This innovative approach fosters a sense of belonging and community
            among homeschooling families. Our platform streamlines the process of connecting with local support networks and resources, making homeschooling more accessible and
            enjoyable than ever before.
          </span>
        </h4>

        <h4 className="sub_heading">
          Homeschool Resorts:&nbsp;
          <span className="main_para">
            HCI introduces dedicated learning spaces known as Homeschool Resorts. These cutting-edge centers are designed to provide co-op groups with optimal environments for
            effective learning. Each resort is carefully curated and organized by subject matter, offering tailor-made educational experiences for different age groups and
            subjects. HCI's Homeschool Resorts are set to redefine the standards of education.
          </span>
        </h4>

        <h4 className="sub_heading">
          Empowering Families:&nbsp;
          <span className="main_para">
            HCI empowers families to take control of their children's education, ensuring they never have to compromise on quality or social opportunities. We're unlocking the
            potential of personalized learning and community support, giving homeschooling families the best of both worlds.
          </span>
        </h4>

        <h4 className="sub_heading">
          What We Need:&nbsp;
          <span className="main_para">
            While HCI has a powerful vision, we need visionary real estate investors who share our passion for reshaping education. We understand that capital is essential, but we
            also value shared values, commitment, and the desire to impact the future of education.
          </span>
        </h4>

        {/* Expect from You */}
        <h2 className="main_heading">What We Expect from You:</h2>

        <h4 className="sub_heading">
          Pioneering Role:&nbsp;
          <span className="main_para">
            As an investor, you'll be at the forefront of revolutionizing education by supporting a game-changing project. Your involvement will be pivotal in shaping the future of
            homeschooling.
          </span>
        </h4>

        <h4 className="sub_heading">
          Impact:&nbsp;
          <span className="main_para">
            You have the opportunity to make a lasting impact on the lives of homeschooling families and the education sector as a whole. Your contributions will create a brighter
            future for families across the nation.
          </span>
        </h4>

        <h4 className="sub_heading">
          Profit Potential:&nbsp;
          <span className="main_para">
            While our primary focus is on education, HCI's unique model also offers strong profit potential in the real estate sector. This is an opportunity for investors to
            benefit financially while making a significant social impact.
          </span>
        </h4>

        <h4 className="sub_heading">
          Influence:&nbsp;
          <span className="main_para">
            Investors will have the chance to collaborate on the development of Homeschool Resorts, helping to shape the learning environments of tomorrow.
          </span>
        </h4>

        <h4 className="sub_heading">
          Join Us in Revolutionizing Education:&nbsp;
          <span className="main_para">
            If you're a real estate investor who believes in the power of education and is eager to be part of a groundbreaking project, we'd love to connect with you. Together, we
            can unlock the full potential of homeschooling and create a brighter future for families. Join us in this educational revolution, and let's redefine the future of
            education together.
          </span>
        </h4>

        {/* Contact Information: */}
        <h4 className="sub_heading info_sub_heading">Contact Information:</h4>

        <div className="link_box">
          <p className="main_para link_para">Email:</p>
          <a href="mailto:info@homeschoolcommunities.org" className="homeschool_site_link">
            info@homeschoolcommunities.org
          </a>
        </div>

        <div className="link_box">
          <p className="main_para link_para">Website:</p>
          <a href="https://homeschoolcommunities.org/" target="_blank" rel="noreferrer" className="homeschool_site_link">
            www.homeschoolcommunities.org
          </a>
        </div>
      </div>

      {/* Button */}
      <div className="button_div real_estate_btn">
        <Button btntext="Download as PDF" PDFlink link={BuildersPdf} />
      </div>
    </div>
  );
};

export default RealEstatePage;
