import React, { useEffect } from "react";

import Button from "../../utils/Button";

import "./style.scss";
import "../HomeSchoolerScreen/style.scss";

const InitiativeProposalPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main_proposal">
      <div className="main_container main_proposal proposal_container">
        <h2 className="main_heading">
          Homeschool Community Initiative Proposal
        </h2>

        {/* Intro */}
        <h2 className="main_heading">Introduction</h2>
        <p className="main_para">
          As homeschooling parents, we understand the importance of providing
          our children with a holistic and enriching educational experience.
          Homeschooling offers the flexibility and individualized learning that
          many families cherish. However, as our children grow and develop, they
          may express curiosity about various aspects of traditional schooling,
          such as access to specialized programs, extracurricular activities,
          and opportunities for socialization. These moments can lead to a
          challenging decision: whether to transition our children into public
          or private schools to meet their evolving needs. At the Homeschool
          Community Initiative, we believe that there's a way to bridge this
          gap, allowing homeschooling families to enjoy the best of both worlds
          without compromising their children's education. Our initiative aims
          to create a supportive ecosystem that offers homeschooling families
          access to resources, programs, and social opportunities, ultimately
          eliminating the need to consider a return to public schooling.
        </p>

        {/* The Problem */}
        <h2 className="main_heading">The Problem</h2>

        <p className="main_para">
          Many homeschooling families encounter common challenges when their
          children reach the middle and high school years:
        </p>

        <h4 className="sub_heading">
          Desire for Specialized Programs:&nbsp;
          <span className="main_para">
            Children may express a desire to explore specialized programs like
            advanced classes, competitive sports, arts, or science labs that are
            often associated with traditional schools.
          </span>
        </h4>

        <h4 className="sub_heading">
          Socialization Needs:&nbsp;
          <span className="main_para">
            As children grow, they may yearn for more opportunities to socialize
            with peers beyond their immediate family, seeking the camaraderie of
            school friends.
          </span>
        </h4>

        <h4 className="sub_heading">
          Graduation Requirements:&nbsp;
          <span className="main_para">
            Homeschooling families often grapple with navigating state
            requirements for high school graduation, which can be complex and
            demanding.
          </span>
        </h4>

        <h4 className="sub_heading">
          Limited Local Support:&nbsp;
          <span className="main_para">
            Many homeschooling families lack access to local homeschooling
            support networks and resources, making it challenging to meet the
            evolving needs of their children.
          </span>
        </h4>

        {/* Our Solution */}
        <h2 className="main_heading">Our Solution</h2>

        <p className="main_para">
          The Homeschool Community Initiative proposes a multi-faceted solution
          to address these challenges:
        </p>

        <h4 className="sub_heading">
          Homeschool Districts:&nbsp;
          <span className="main_para">
            We aim to create homeschool districts, subdividing neighborhoods to
            form local homeschool communities. These districts will serve as the
            foundation for our initiative, connecting homeschooling families
            with nearby resources and like-minded parents.
          </span>
        </h4>

        <h4 className="sub_heading">
          Online Platform:&nbsp;
          <span className="main_para">
            To facilitate communication and resource-sharing within these
            districts, we will develop an online platform. This platform will
            enable homeschooling families to register within their district,
            automatically connecting them with local support networks.
          </span>
        </h4>

        <h4 className="sub_heading">
          Resource Access: &nbsp;
          <span className="main_para">
            Within these homeschool districts, we plan to establish "homeschool
            resorts." These resorts will be located in subdivisions of homes
            designated for homeschooling activities. Homeschoolers within these
            districts can access these resorts, booking learning spaces in homes
            and participating in sports programs, arts classes, and science labs
            in community clubhouses.
          </span>
        </h4>

        {/* Why It Matters */}
        <h2 className="main_heading">Why It Matters</h2>

        <p className="main_para">
          Our initiative addresses several key concerns:
        </p>

        <h4 className="sub_heading">
          Personalized Education:&nbsp;
          <span className="main_para">
            Homeschooling families will continue to offer personalized education
            to their children while gaining access to additional resources.
          </span>
        </h4>

        <h4 className="sub_heading">
          Community and Socialization:&nbsp;
          <span className="main_para">
            Our platform fosters a sense of community among homeschooling
            families, providing children with opportunities to socialize and
            build lasting friendships.
          </span>
        </h4>

        <h4 className="sub_heading">
          Graduation Requirements:&nbsp;
          <span className="main_para">
            By creating local support networks, we'll assist homeschooling
            families in navigating graduation requirements efficiently.
          </span>
        </h4>

        <h4 className="sub_heading">
          Eliminating the Need for Public Schooling:&nbsp;
          <span className="main_para">
            Ultimately, our initiative aims to empower homeschooling families to
            provide a well-rounded education and social experiences for their
            children, reducing the need to consider public or private school
            alternatives.
          </span>
        </h4>

        {/*  */}
        <h2 className="main_heading">
          Recognition, Collaboration, and Reimagining Education Standards:
        </h2>
        <p className="main_para">
          As our initiative gains momentum, we envision the possibility of
          companies and colleges recognizing the unique value of homeschooling
          communities. Just as they engage with traditional public schools,
          these entities may find our homeschooling networks equally appealing.
          Homeschooling students often excel academically and bring a diverse
          range of skills and talents to the table. This recognition could open
          doors to partnerships, scholarships, and internship opportunities that
          directly benefit our homeschooling families.
        </p>

        {/* Get Involved */}
        <h2 className="main_heading">Get Involved</h2>
        <p className="main_para">
          We invite you to join our movement, explore your local homeschool
          district, and become part of a community that supports your
          homeschooling journey. Together, we can create a better future for our
          children and revolutionize the homeschooling experience.
        </p>

        {/* Button */}
        <div className="button_div about_btn">
          <Button btntext="Sign Our Petition" bg link="/sign-our-petition" />
        </div>
      </div>
    </div>
  );
};

export default InitiativeProposalPage;
