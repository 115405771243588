import React from "react";

import GreenArrow from "../../Assets/Images/green-check.svg";
import CloseIcon from "../../Assets/Images/close.svg";

import "./style.scss";

const Index = ({ text, setsuccessText }) => {
  return (
    text && (
      <div className="stand-alone-wrapper">
        <div className="text-box">
          <img src={GreenArrow} alt="type" />
          <p className="p">{text}</p>
        </div>
        <img
          onClick={() => setsuccessText("")}
          src={CloseIcon}
          alt="type"
          className="close-btn"
        />
      </div>
    )
  );
};

export default Index;
