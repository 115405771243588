import React from "react";

import footerLogoImage from "../../Assets/Images/logo.png";
import { FaEnvelope } from "react-icons/fa";
import { ImPhone } from "react-icons/im";

import "./style.scss";

const FooterComponent = ({ homepage }) => {
  return (
    <div className="footerMainOuterWrapper">
      <div className="inner-container">
        <div className="footer_logo_div">
          <div className="footerLogoImageWrapper">
            <img src={footerLogoImage} alt="" />
          </div>
          <p className="logo_text">
            Empowering Homeschool, One Neighborhood At a Time
          </p>
        </div>

        <div className="footerContentWrapper">
          <h2>Contact</h2>

          <div className="footerContentPointerWrapper">
            {/* <div className="footerIconWrapper">
              <ImPhone size={22} color={"#455467"} />
              <p>
                <a href="tel:+8019412815">+801 - 941-2815</a>
              </p>
            </div> */}
            <div className="footerIconWrapper">
              <FaEnvelope size={22} color={"#455467"} />
              <p>
                <a href="mailto:lisa@homeschoolcommunities.org">
                  info@homeschoolcommunities.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
