import React from "react";

import { Link } from "react-router-dom";
import "./style.scss";

const Button = (props) => {
  const { type, link, btntext, bg, bgWhite, cta, disabled, PDFlink } = props;

  return (
    <>
      {link ? (
        <Link to={link} target={PDFlink ? "_blank" : ""} className={`${bg && "bg-color"} ${bgWhite && "bgWhite"} ${disabled && "disabled"} btn`}>
          <p className={`${bgWhite ? "text_color" : "btn_text"}`}>{btntext}</p>
        </Link>
      ) : (
        <button
          {...props}
          disabled={disabled ? true : false}
          type={type}
          className={`${bg && "bg-color"} ${disabled && "disabled"} btn`}
          onClick={(e) => {
            if (cta) {
              cta(e);
            }
          }}
          style={{ cursor: "pointer" }}
        >
          <p className="btn_text">{btntext}</p>
        </button>
      )}
    </>
  );
};

export default Button;
