import React, { useEffect, useState } from "react";

import Button from "../../utils/Button";
import AboutBanner from "../../Assets/Images/about-banner.png";
import AboutVision from "../../Assets/Images/about-vision.png";
import BuildingVision from "../../Assets/Images/about-vision-building.png";
import AboutFuture from "../../Assets/Images/about-future.png";
import AboutConnection from "../../Assets/Images/about-connection.png";
import AboutStatus from "../../Assets/Images/about-status.png";

import "./style.scss";
import "../HomeSchoolerScreen/style.scss";
import BasicHeadingCard from "../../utils/BasicCardHeading";

const AboutOurMovementPage = () => {
  return (
    <div className="">
      <div className="hero_container">
        {/* Hero Banner */}
        <div className="about_banner common">
          <div className="about_card">
            <BasicHeadingCard
              heading="Our Vision"
              br
              heading2="for Homeschool Communities"
              paragraph="Hello. Welcome to the future of homeschooling! We’re thrilled to introduce you to our vision, even though it’s not quite a reality... yet. Let’s explain."
            />
          </div>
          <img src={AboutBanner} alt="banner logo" />
        </div>
        {/* The Challenge */}
        <div className="bg_challenge">
          <div className="challenge common">
            <div className="all_about">
              <h3 className="all_about_heading">What We’re All About</h3>
              <p className="all_about_text">
                As homeschooling parents, we’ve experienced the unique benefits
                of providing personalized education and flexibility for our
                children. We cherish the incredible journey that homeschooling
                offers and are dedicated to enhancing this experience.
              </p>
            </div>
            <div className="all_about all_challenge">
              <h3 className="all_about_heading all_challenge_heading">
                The Challenge
              </h3>
              <p className="all_about_text all_challenge_text">
                However, we also understand the challenges that can arise as our
                children grow. There comes a point when they may express
                curiosity about what public schools offer or even feel isolated
                when they see their neighborhood peers heading off to school
                every day. We’ve all been there. These challenges can lead some
                homeschooling families to consider returning to public school,
                especially when they face state requirements for graduation,
                which can add stress to the homeschooling experience.{" "}
              </p>
            </div>
          </div>
        </div>
        {/* Our Vision */}
        <div className="our_vision common">
          <img src={AboutVision} alt="vision image" />
          <div className="vision_card">
            <BasicHeadingCard
              heading="Our "
              textLeft
              heading2="Vision"
              paragraph="To address these challenges, we're passionate about an initiative that we hope to ignite—a movement that empowers homeschooling families and skilled parents to create their own competitive sports programs, advanced class electives like orchestra and theater, and much more. Our ultimate goal is to ensure that our children never have to question or consider returning to public school because homeschoolers will finally have their own supportive community."
            />
          </div>
        </div>
        {/* We’re Building */}
        <div className="our_building">
          <div className="our_vision our_inner_building common">
            <div className="vision_card">
              <BasicHeadingCard
                heading="What We’re "
                textLeft
                heading2="Building"
                paragraph="Here's where things get exciting: We're building a website that will divide neighborhoods into homeschool districts. These districts will simplify the process for busy parents, identifying nearby homeschoolers and automatically assigning them to their respective district when they register. If you prefer, you'll also have the option to form your own group within the district."
              />
            </div>
            <img src={BuildingVision} alt="vision image" />
          </div>
        </div>
        {/*  The Future */}
        <div className="our_future common">
          <div className="future_card">
            <BasicHeadingCard
              heading="The "
              headingSize
              textLeft
              heading2="Future"
              paragraph="Our vision doesn't stop there. We're planning designated homeschool resorts within specific district boundaries. These resorts will be located in subdivisions of homes zoned for homeschooling activities, ready for when your children express interest in public school offerings. By creating these homeschool district boundaries, we're essentially forming neighborhood groups."
            />
          </div>
          <img src={AboutFuture} alt="future image" />
        </div>
        {/* Show Your Support */}
        <div className="bg_support">
          <div className="show_support">
            <div className="our_support common">
              <div className="future_card">
                <BasicHeadingCard
                  heading="Show Your "
                  textLeft
                  color
                  heading2="Support"
                  paragraph="To bring this vision to life, we're gathering support through a formal petition. We invite you to sign the petition to show your support and interest in our initiative. This will help us gauge the demand and support for such a community."
                />
              </div>
            </div>
          </div>
        </div>
        {/* Exploration */}
        <div className="connection_building">
          <div className="our_vision our_connection common">
            <div className="connection_card">
              <img src={AboutConnection} alt="vision image" />
              <div className="explore_content">
                <BasicHeadingCard
                  heading="Exploration and "
                  textLeft
                  br
                  heading2="Connection"
                />
                <p className="connection_para">
                  Co-ops and homeschool groups within these districts will have
                  the option to book learning spaces in homes, while sports
                  programs will be available on dedicated fields. Auditoriums
                  and science labs in the community clubhouse will provide
                  additional resources. Although registration is all it takes to
                  become a part of your local homeschool district, we want to
                  clarify that this is not a school—it's a resource-sharing
                  platform designed to empower homeschooling families.
                </p>
              </div>
            </div>
          </div>

          {/* Current Status */}
          <div className="our_status common">
            <img src={AboutStatus} alt="vision image" />
            <div className="status_card">
              <BasicHeadingCard
                heading="The Current "
                textLeft
                heading2="Status"
                paragraph="Here's the important part: Our website and full functionalities are not available yet. This is a concept—a vision that we're eager to bring to life. Right now, we're in the process of gathering support and interest through the formal petition."
              />
            </div>
          </div>

          {/*  Join Us */}
          <div className="our_join common">
            <div className="our_card">
              <BasicHeadingCard
                heading="Join "
                headingSize
                textLeft
                color
                textColor
                heading2="Us"
                paragraph="We invite you to sign the petition to show your support and join our movement. Your interest and support mean the world to us, and we can't wait to take this exciting journey with you."
              />
              <div className="view_btn">
                <Button
                  link="/initiative-proposal"
                  btntext="View Initiative Proposal"
                  bgWhite
                />
              </div>
            </div>
          </div>
        </div>
        {/*  Our Commitment */}
        <div className="our_commitment common">
          <div className="future_card">
            <BasicHeadingCard
              heading="Our "
              headingSize
              textLeft
              heading2="Commitment"
              paragraph="We are fully committed to revolutionizing the homeschooling experience, giving our children the best of both worlds without ever having to compromise their education or socialization. Together, we can make it happen.
              Thank you for joining us on this journey!"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOurMovementPage;
