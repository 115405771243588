import React, { useState } from "react";

import { Link, useLocation } from "react-router-dom";

import logoImage from "../../Assets/Images/logo.png";
import DownArrow from "../../Assets/Images/downArrow.svg";
import LeftArrow from "../../Assets/Images/leftArrow.svg";
import MenuIcon from "../../Assets/Images/menu.svg";
import CloseIcon from "../../Assets/Images/close.svg";

import "./style.scss";

const Header = ({ homepage }) => {
  const [dropdown, setDropdown] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [menu, setMenu] = useState(false);

  const location = useLocation();

  return (
    <>
      {true ? (
        <>
          <div
            onMouseLeave={() => {
              setDropdown(false);
              setDropdown2(false);
            }}
            className="headerMainOuterWrapper"
          >
            <div className="header-content-wrapper">
              <Link
                to="/"
                onClick={() => {
                  setMenu(false);
                }}
                className="logoImageWrapper"
              >
                <img src={logoImage} alt="Homeschool" className="logo" />
                <p className="logo-name">Homeschool Communities</p>
              </Link>
              {/* nav */}
              {location.pathname === "/blog" ||
              location.pathname === "/articles" ? (
                <div className="nav-links">
                  <Link
                    onMouseEnter={() => {
                      setDropdown(false);
                      setDropdown2(false);
                    }}
                    to="/blog"
                    className="nav-link nav-a"
                    style={{
                      fontWeight:
                        location.pathname === "/blog" ? "bold" : "500",
                    }}
                  >
                    Podcast
                  </Link>
                  <Link
                    onMouseEnter={() => {
                      setDropdown(false);
                      setDropdown2(false);
                    }}
                    to="/articles"
                    className="nav-link nav-a"
                    style={{
                      fontWeight:
                        location.pathname === "/articles" ? "bold" : "500",
                    }}
                  >
                    Articles
                  </Link>
                </div>
              ) : (
                <div className="nav-links">
                  <div className="nav-dropdown">
                    <div
                      onClick={() => {
                        setDropdown(!dropdown);
                        setDropdown2(false);
                      }}
                      className="drop-name"
                    >
                      <p className="nav-link">Explore Our Initiative</p>
                      <span>
                        <img src={DownArrow} alt="arrow" />
                      </span>
                    </div>

                    {/* dropdown menu */}
                    <div
                      onMouseLeave={() => setDropdown(false)}
                      className={`dropdown-menu  ${
                        dropdown ? "show" : "hidden"
                      }`}
                    >
                      <div className="menu-item-box">
                        <Link
                          to="/about-our-movement"
                          className="nav-link menu-item"
                          onClick={() => setDropdown(false)}
                        >
                          About Our Movement
                        </Link>
                        <Link
                          to="/initiative-proposal"
                          className="nav-link menu-item"
                          onClick={() => setDropdown(false)}
                        >
                          Initiative Proposal
                        </Link>
                        <Link
                          to="/sign-our-petition"
                          className="nav-link menu-item"
                          onClick={() => setDropdown(false)}
                        >
                          Sign the Petition
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="nav-dropdown">
                    <div
                      onClick={() => {
                        setDropdown2(!dropdown2);
                        setDropdown(false);
                      }}
                      className="drop-name"
                    >
                      <p className="nav-link">Your Involvement</p>
                      <span>
                        <img src={DownArrow} alt="arrow" />
                      </span>
                    </div>
                    {/* dropdown menu */}
                    <div
                      onMouseLeave={() => setDropdown2(false)}
                      className={`dropdown-menu  ${
                        dropdown2 ? "show" : "hidden"
                      }`}
                    >
                      <div className="menu-item-box">
                        <Link
                          to="/builders-and-realtors"
                          className="nav-link menu-item"
                          onClick={() => setDropdown2(false)}
                        >
                          Builders and Realtors
                        </Link>
                        <Link
                          to="/home-buyers"
                          className="nav-link menu-item"
                          onClick={() => setDropdown2(false)}
                        >
                          Home Buyers
                        </Link>
                        <Link
                          to="/homeschooling-families"
                          className="nav-link menu-item"
                          onClick={() => setDropdown2(false)}
                        >
                          Homeschooling Families
                        </Link>
                        <Link
                          to="/real-estate-investors"
                          className="nav-link menu-item"
                          onClick={() => setDropdown2(false)}
                        >
                          Real Estate Investors
                        </Link>
                      </div>
                    </div>
                  </div>

                  <Link
                    onMouseEnter={() => {
                      setDropdown(false);
                      setDropdown2(false);
                    }}
                    to="/blog"
                    className="nav-link nav-a"
                  >
                    Blog
                  </Link>
                </div>
              )}
              {/* menu btn */}
              <span onClick={() => setMenu(!menu)} className="menu-btns">
                <img src={menu ? CloseIcon : MenuIcon} alt="Menu Icon" />
              </span>
            </div>
          </div>
          {menu && (
            <div className={`mobile-menu ${menu && "active-menu"} `}>
              {location.pathname === "/blog" ||
              location.pathname === "/articles" ? (
                <div className="nav-links">
                  <Link
                    onClick={() => {
                      setMenu(false);
                    }}
                    to="/blog"
                    className="nav-link nav-a"
                    style={{
                      fontWeight:
                        location.pathname === "/blog" ? "bold" : "500",
                    }}
                  >
                    Podcast
                  </Link>
                  <Link
                    onClick={() => {
                      setMenu(false);
                    }}
                    to="/articles"
                    className="nav-link nav-a"
                    style={{
                      fontWeight:
                        location.pathname === "/articles" ? "bold" : "500",
                    }}
                  >
                    Articles
                  </Link>
                </div>
              ) : (
                <div className="nav-links">
                  <div className="nav-dropdown">
                    <div
                      onClick={() => {
                        setDropdown(!dropdown);
                        setDropdown2(false);
                      }}
                      className="drop-name"
                    >
                      <p className="nav-link">Explore Our Initiative</p>
                      <span>
                        <img src={DownArrow} alt="arrow" />
                      </span>
                    </div>

                    {/* dropdown menu */}
                    <div
                      onMouseLeave={() => setDropdown(false)}
                      className={`dropdown-menu  ${
                        dropdown ? "show" : "hidden"
                      }`}
                    >
                      <div className="menu-item-box">
                        <Link
                          to="/about-our-movement"
                          className="nav-link menu-item"
                          onClick={() => {
                            setMenu(false);
                            setDropdown(false);
                          }}
                        >
                          About Our Movement
                        </Link>
                        <Link
                          to="/initiative-proposal"
                          className="nav-link menu-item"
                          onClick={() => {
                            setMenu(false);
                            setDropdown(false);
                          }}
                        >
                          Initiative Proposal
                        </Link>
                        <Link
                          to="/sign-our-petition"
                          className="nav-link menu-item"
                          onClick={() => {
                            setMenu(false);
                            setDropdown(false);
                          }}
                        >
                          Sign the Petition
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="nav-dropdown">
                    <div
                      onClick={() => {
                        setDropdown2(!dropdown2);
                        setDropdown(false);
                      }}
                      className="drop-name"
                    >
                      <p className="nav-link">Your Involvement</p>
                      <span>
                        <img src={DownArrow} alt="arrow" />
                      </span>
                    </div>
                    {/* dropdown menu */}
                    <div
                      onMouseLeave={() => setDropdown2(false)}
                      className={`dropdown-menu  ${
                        dropdown2 ? "show" : "hidden"
                      }`}
                    >
                      <div className="menu-item-box">
                        <Link
                          to="/builders-and-realtors"
                          className="nav-link menu-item"
                          onClick={() => {
                            setMenu(false);
                            setDropdown2(false);
                          }}
                        >
                          Builders and Realtors
                        </Link>
                        <Link
                          to="/home-buyers"
                          className="nav-link menu-item"
                          onClick={() => {
                            setMenu(false);
                            setDropdown2(false);
                          }}
                        >
                          Home Buyers
                        </Link>
                        <Link
                          to="/homeschooling-families"
                          className="nav-link menu-item"
                          onClick={() => {
                            setMenu(false);
                            setDropdown2(false);
                          }}
                        >
                          Homeschooling Families
                        </Link>
                        <Link
                          to="/real-estate-investors"
                          className="nav-link menu-item"
                          onClick={() => {
                            setMenu(false);
                            setDropdown2(false);
                          }}
                        >
                          Real Estate Investors
                        </Link>
                      </div>
                    </div>
                  </div>

                  <Link
                    onMouseEnter={() => {
                      setDropdown(false);
                      setDropdown2(false);
                    }}
                    to="/blog"
                    className="nav-link nav-a"
                  >
                    Blog
                  </Link>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <div
          className={
            location.pathname === "/articles"
              ? "formal-main-wrapper blog-header-wrapper"
              : "formal-main-wrapper"
          }
        >
          <div
            className={
              location.pathname === "/articles"
                ? `formal-header blog-header`
                : `formal-header`
            }
          >
            <Link className="back-btn" to="/">
              <span>
                <img src={LeftArrow} width={11} height={22} alt="arrow" />
              </span>
              <p>Back to home</p>
            </Link>
            <img src={logoImage} alt="logo" className="web-logo" />
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
