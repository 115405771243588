import React from "react";

import "./style.scss";
const BasicHeadingCard = (props) => {
  const { heading, heading2, paragraph, border, br, fontSize, textLeft, headingSize, color, textColor } = props;
  return (
    <div className={`basicHeading ${textLeft && "basicHeading-2"} ${headingSize && "basicHeading-3"}`}>
      {heading && (
        <h1 className={`${fontSize ? "heading" : ""} ${color && "heading heading-color"}`}>
          {heading}
          {br && <br />}
          {heading2 && <span>{heading2}</span>}
        </h1>
      )}

      {border && <div className="border"></div>}
      {paragraph && (
        <p className={`${paragraph && "before_after_para"} ${color && "para-color"} ${textColor && "text-color"} ${textLeft && " para-3"}`}>
          {paragraph}
          {/* {/ {bold && <span style={{ fontWeight: "600px" }}>{bold}</span>} /} */}
        </p>
      )}
    </div>
  );
};
export default BasicHeadingCard;
