import React, { useEffect, useRef, useState } from 'react';

import { Formik } from 'formik';
import SignaturePad from 'react-signature-canvas';
import { useToasts } from 'react-toast-notifications';
import Button from '../../utils/Button';
import StandAlone from '../../utils/StandAlone';
import { SignedPetiotion, getSignedPetition } from '../../API';
import './style.scss';
import '../HomeSchoolerScreen/style.scss';
import Closeicon from '../../Assets/Images/close.svg';

const ArticlePageScreen = () => {
  const sigPad = useRef();
  const [trimSig, setTrimsig] = useState();
  const [allSigns, setAllSigns] = useState([]);
  const { addToast } = useToasts();
  const [successText, setsuccessText] = useState('');

  const getAPI = async () => {
    const result = await getSignedPetition();
    console.log(result);
    setAllSigns(result?.data);
  };
  useEffect(() => {
    getAPI();
  }, []);

  return (
    <div className="formal-main-wrapper">
      <div className="formal-content">
        <h2 className="main_heading">
          Petition: Support Homeschool-Friendly Neighborhoods
        </h2>
        <br />
        <h2 className="sub_heading">Introduction:</h2>
        <br />
        <p className="main_para no_margin_text">
          We, the undersigned, believe in the power of homeschooling and its
          benefits for children. We're excited to support the initiative to
          create homeschool-friendly neighborhoods, also known as "Homeschool
          Resorts." These communities are designed to provide resources,
          opportunities, and a supportive environment for homeschooling
          families. By signing this petition, you express your interest in these
          communities and join our movement to make them a reality.
        </p>
      </div>

      <div className="form-box">
        <Formik
          initialValues={{
            email: '',
            fullname: '',
            address: '',
            homebuyer_interested_in_homeschool_friendly_neighborhoods: false,
            skilled_Parent_or_certified_teacher: false,
            Homeschooler: false,
            Real_Estate_Investor_Interested_in_Building_Homeschool_Friendly_Neighborhoods: false,
            check4: false,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.address) {
              errors.address = 'Required';
            }
            if (!values.fullname) {
              errors.fullname = 'Required';
            }
            if (!values.email) {
              errors.email = 'Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }
            if (
              !values.homebuyer_interested_in_homeschool_friendly_neighborhoods &&
              !values.skilled_Parent_or_certified_teacher &&
              !values.Homeschooler &&
              !values.Real_Estate_Investor_Interested_in_Building_Homeschool_Friendly_Neighborhoods
            ) {
              errors.homebuyer_interested_in_homeschool_friendly_neighborhoods =
                'Required';
            }

            if (!values.check4) {
              errors.check4 = 'Required';
            }

            return errors;
          }}
          onSubmit={async (values, { resetForm }) => {
            console.log(trimSig);
            const result = await SignedPetiotion(
              {
                email: values.email,
                name: values.fullname,
                address: values.address,
                homebuyer_interested_in_homeschool_friendly_neighborhoods:
                  values.homebuyer_interested_in_homeschool_friendly_neighborhoods,
                skilled_Parent_or_certified_teacher:
                  values.skilled_Parent_or_certified_teacher,
                Homeschooler: values.Homeschooler,
                Real_Estate_Investor_Interested_in_Building_Homeschool_Friendly_Neighborhoods:
                  values.Real_Estate_Investor_Interested_in_Building_Homeschool_Friendly_Neighborhoods,

                signature: trimSig,
              },
              addToast,
              resetForm
            );
            if (result.status === 201) {
              setsuccessText(
                'Thank you for your support! Your signature helps us make a difference in our community.'
              );
              sigPad.current?.clear();
              getAPI();
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit} className="form_container ">
              <div className="input-fields">
                <div className="input-box">
                  <label>Full Name (Required):</label>
                  <input
                    type="text"
                    name="fullname"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fullname}
                  />
                  {errors.fullname && touched.fullname && (
                    <p className="error">{errors.fullname}</p>
                  )}
                </div>
                <div className="input-box">
                  <label>Address (Required):</label>
                  <input
                    type="text"
                    name="address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address}
                  />
                  {errors.address && touched.address && (
                    <p className="error">{errors.address}</p>
                  )}
                </div>
                <div className="input-box">
                  <label>Email Address (Required):</label>
                  <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.email && touched.email && (
                    <p className="error">{errors.email}</p>
                  )}
                </div>
              </div>
              <div className="checkbox-box">
                <h2 className="form_sub_heading">Are You a (Required):</h2>
                {/*  */}
                <div className="checkbox">
                  <input
                    type="checkbox"
                    id="check3"
                    name="Homeschooler"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.Homeschooler}
                  />
                  <label className="checkbox_para" for="check3">
                    Homeschooler
                  </label>
                </div>
                <div className="checkbox">
                  <input
                    type="checkbox"
                    id="check1"
                    name="homebuyer_interested_in_homeschool_friendly_neighborhoods"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={
                      values.homebuyer_interested_in_homeschool_friendly_neighborhoods
                    }
                  />
                  <label className="checkbox_para" for="check1">
                    Homebuyer Interested in Homeschool-Friendly Neighborhoods
                  </label>
                </div>
                {/*  */}
                <div className="checkbox">
                  <input
                    type="checkbox"
                    id="check2"
                    name="skilled_Parent_or_certified_teacher"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.skilled_Parent_or_certified_teacher}
                  />
                  <label className="checkbox_para" for="check2">
                    Skilled Parent or Certified Teacher
                  </label>
                </div>

                <div className="checkbox">
                  <input
                    type="checkbox"
                    id="check2"
                    name="Real_Estate_Investor_Interested_in_Building_Homeschool_Friendly_Neighborhoods"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={
                      values.Real_Estate_Investor_Interested_in_Building_Homeschool_Friendly_Neighborhoods
                    }
                  />
                  <label className="checkbox_para" for="check2">
                    Real Estate Investor Interested in Building
                    Homeschool-Friendly Neighborhoods
                  </label>
                </div>

                {/*  */}
              </div>

              <p className="sign-in-text">
                To sign with your electronic signature, click and hold the left
                mouse button (or use your mousepad) to draw your signature in
                the box below:
              </p>

              {/* signature */}
              <div className="sigPad-design ">
                <SignaturePad
                  penColor="black"
                  canvasProps={{
                    width: 423,
                    height: 226,
                    className: 'sigCanvas',
                  }}
                  ref={sigPad}
                  onEnd={() => {
                    setTrimsig(
                      sigPad.current?.getTrimmedCanvas().toDataURL('image/png')
                    );
                  }}
                />

                <button
                  type="button"
                  onClick={() => {
                    sigPad.current?.clear();
                  }}
                >
                  <img src={Closeicon} alt="close" />
                </button>
              </div>

              <div className="submit_checkbox">
                <input
                  type="checkbox"
                  id="check4"
                  name="check4"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.check4}
                />
                <label className="submit_para" for="check4">
                  I confirm that my signature represents my genuine support for
                  the Homeschool Community Initiative, and I am a real person.
                </label>
              </div>
              {successText && (
                <div style={{ marginTop: '30px', maxWidth: '564px' }}>
                  <StandAlone
                    text={successText}
                    setsuccessText={setsuccessText}
                  />
                </div>
              )}
              <div className="btn_box">
                <Button
                  btntext={isSubmitting ? 'Submiting ...' : 'Submit'}
                  bg
                  type="submit"
                  disabled={
                    values.fullname &&
                    values.address &&
                    values.email &&
                    Object.keys(errors)?.length === 0
                      ? false
                      : true
                  }
                />
                <p className="info-text">
                  Signatures: <strong>{allSigns?.length}</strong> &nbsp;
                  {!allSigns?.length <= 0 && (
                    <>
                      (
                      <strong>
                        {Math.floor(
                          (allSigns.filter(
                            (data) =>
                              data.homebuyer_interested_in_homeschool_friendly_neighborhoods
                              || data.Real_Estate_Investor_Interested_in_Building_Homeschool_Friendly_Neighborhoods
                          )?.length /
                            allSigns?.length) *
                            100
                        )}
                        %&nbsp;
                      </strong>
                      'Interested in Investment Opportunities')
                    </>
                  )}
                </p>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ArticlePageScreen;
