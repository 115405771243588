import React from "react";
import "./style.scss";
import { Col, Row } from "antd";
import FirstFormComponent from "../FirstFormComponent";
import SecondFormComponent from "../SecondFormComponent";
import ThirdFormComponent from "../ThirdFormComponent";
import FourFormComponent from "../fourFormComponent";
const CompleteFormSectionComponent = () => {
  return (
    <section className="completeFormSectionWrapper">
      <div className="home-forms-section">
        <div className="firstFormSectionWrapper">
          <p>Please fill in your contact information below.</p>
          <div className="ListSectionWrapper">
            <ul>
              <li>
                I am a homebuilder interested in building a homeschool
                community.
              </li>
              <li>
                I am a commercial builder and interested in building some of the
                resources offered in a homeschool community.
              </li>
            </ul>
          </div>

          <FirstFormComponent />
        </div>

        <div className="firstFormSectionWrapper">
          <SecondFormComponent />
        </div>
      </div>
      <div className="home-forms-section">
        <div className="firstFormSectionWrapper">
          <p>Please fill in your contact information below.</p>
          <div className="ListSectionWrapper">
            <ul>
              <li>
                I would be interested in renting my home to homeschool groups
                for passive income opportunity
              </li>
              <li>
                I'd open a class at home to support co-op enrichment activities
                if I had an extra space available.
              </li>
              <li>
                I'd teach classes in the clubhouse because I'm passionate about
                it.
              </li>
            </ul>
          </div>

          <ThirdFormComponent />
        </div>
        <div className="firstFormSectionWrapper">
          <FourFormComponent />
        </div>
      </div>
    </section>
  );
};

export default CompleteFormSectionComponent;
