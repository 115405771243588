import React from "react";
import "./style.scss";
import { Switch } from "antd";

const ToggleComponent = ({ name, detail, count, statusData, handleVote }) => {
  return (
    <section className="toggleWrapperStyle">
      <div className="toggle-top-text">
        <span className="spanToggleNumberStyle">
          {/*{statusData === true ? 1 : 0}*/}
          {count}
        </span>
        <p className="paraToggleStyle">{detail}</p>
      </div>
      <Switch
        checkedChildren="Yes"
        unCheckedChildren=""
        checked={statusData ? statusData : false}
        onClick={() => handleVote(name)}
        className="support-switch"
      />
    </section>
  );
};

export default ToggleComponent;
