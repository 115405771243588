import axios from 'axios';

const apiUrl = 'https://nodeapp.homeschoolcommunities.org';
//const apiUrl = 'http://localhost:3001';
const homeBuilderApi = async (
  values,
  addToast,
  resetForm,
  setLoading,
  setCheckedBox
) => {
  setLoading(true);

  return await axios
    .post(`${apiUrl}/api/forms/one`, values)
    .then((resp) => {
      resetForm({ values: '' });
      setLoading(false);
      setCheckedBox({ home_builder: false, commercial_builder: false });
      return resp;
      //   addToast(`Successfully Submitted! Please make sure to check your e-mail inbox for our engagement email and add us to your list as we might be in your spam folder.` , {appearance: 'success'});
    })
    .catch((err) => {
      setLoading(false);
      addToast(`${err?.response?.data?.error_msg}`, { appearance: 'error' });
    });
};

export default homeBuilderApi;

export const RealtorBuilderApi = async (
  values,
  addToast,
  resetForm,
  setLoading,
  setCheckedBox
) => {
  setLoading(true);

  return await axios
    .post(`${apiUrl}/api/forms/two`, values)
    .then((resp) => {
      setLoading(false);
      resetForm({ values: '' });
      setCheckedBox(false);
      return resp;
      // addToast(`Successfully Submitted! Please make sure to check your e-mail inbox for our engagement email and add us to your list as we might be in your spam folder.` , {appearance: 'success'});
    })
    .catch((err) => {
      setLoading(false);
      addToast(`${err?.response?.data?.error_msg}`, { appearance: 'error' });
      return err;
    });
};

export const RealEstateApi = async (
  values,
  addToast,
  resetForm,
  setLoading,
  setCheckedBox
) => {
  setLoading(true);

  return await axios
    .post(`${apiUrl}/api/forms/four`, values)
    .then((resp) => {
      setLoading(false);
      resetForm({ values: '' });
      setCheckedBox(false);
      return resp;
      // addToast(`Successfully Submitted! Please make sure to check your e-mail inbox for our engagement email and add us to your list as we might be in your spam folder.` , {appearance: 'success'});
    })
    .catch((err) => {
      setLoading(false);
      addToast(`${err?.response?.data?.error_msg}`, { appearance: 'error' });
      return err;
    });
};
export const ThirdFormBuilderApi = async (
  values,
  addToast,
  setLoading,
  setCheckedBox,
  resetForm
) => {
  setLoading(true);

  return await axios
    .post(`${apiUrl}/api/forms/three`, values)
    .then((resp) => {
      setLoading(false);
      resetForm({ values: '' });
      setCheckedBox({
        homeschool: false,
        teacher_or_skilled: false,
        wish_we_had: false,
        advocate_for: false,
      });
      //   addToast(`Successfully Submitted! Please make sure to check your e-mail inbox for our engagement email and add us to your list as we might be in your spam folder.` , {appearance: 'success'});
      return resp;
    })
    .catch((err) => {
      setLoading(false);
      addToast(`${err?.response?.data?.error_msg}`, { appearance: 'error' });
    });
};

export const SignedPetiotion = async (values, addToast, resetForm) => {
  return await axios
    .post(`${apiUrl}/api/forms/petition`, values)
    .then((resp) => {
      resetForm({ values: '' });

      return resp;
    })
    .catch((err) => {
      addToast(`${err?.response?.data?.error_msg}`, { appearance: 'error' });
    });
};

export const getSignedPetition = async () => {
  return await axios
    .get(`${apiUrl}/api/forms/petition`)
    .then((resp) => {
      return resp;
    })
    .catch((err) => {});
};

export const VotingApi = async (body, addToast, setData, setLoading) => {
  if (body !== '') {
    setLoading(true);
    await axios
      .post(`${apiUrl}/api/poll`, body)
      .then((resp) => {
        setData(resp?.data);
        setLoading(false);
        addToast(`Thank you for your vote.`, { appearance: 'success' });
      })
      .catch((err) => {
        setLoading(false);
        addToast(`${err?.response?.data?.error_msg}`, { appearance: 'error' });
      });
  } else {
    await axios
      .post(`${apiUrl}/api/poll`, {})
      .then((resp) => {
        setLoading(false);
        setData(resp?.data);
      })
      .catch((err) => {
        setLoading(false);
        addToast(`${err?.response?.data?.error_msg}`, { appearance: 'error' });
      });
  }
};

export const VotingApiFirst = async (body, addToast, setData, setLoading) => {
  if (body !== '') {
    setLoading(true);
    await axios
      .post(`${apiUrl}/api/poll`, body)
      .then((resp) => {
        setData(resp?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        addToast(`${err?.response?.data?.error_msg}`, { appearance: 'error' });
      });
  }
};
