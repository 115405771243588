import { Fragment } from "react";

import Header from "./Components/Header";
import FooterComponent from "./Components/FooterComponent";

import "antd/dist/reset.css";
import "./Assets/Scss/main.scss";

import { Routes, Route } from "react-router-dom";
import LandingPageContainer from "./Containers/LandingPageContainer";
import ArticlePageScreen from "./Screens/ArticlePageScreen";
import FormalPetitionScreen from "./Screens/FormalPetition";
import HomeSchoolerScreen from "./Screens/HomeSchoolerScreen";
import BuilderScreen from "./Screens/BuilderScreen";
import RealtorsScreen from "./Screens/RealtorsScreen";
import AboutOurMovementScreen from "./Screens/AboutOurMovementScreen";
import InitiativeProposalScreen from "./Screens/InitiativeProposalScreen";
import RealEstateScreen from "./Screens/RealEstateScreen";
import { useLocation } from "react-router-dom";
import BlogScreen from "./Screens/BlogScreen";

function App() {
  const location = useLocation();
  return (
    <Fragment>
      {location.pathname === "/" ||
      location.pathname === "/sign-our-petition" ||
      location.pathname === "/initiative-proposal" ||
      location.pathname === "/about-our-movement" ||
      location.pathname === "/blog" ? (
        <Header homepage />
      ) : (
        <Header />
      )}
      <Routes>
        <Route path="/" element={<LandingPageContainer />} />
        <Route path="/articles" element={<ArticlePageScreen />} />
        <Route path="/blog" element={<BlogScreen />} />
        <Route
          path="/homeschooling-families"
          element={<HomeSchoolerScreen />}
        />
        <Route path="/builders-and-realtors" element={<BuilderScreen />} />
        <Route path="/home-buyers" element={<RealtorsScreen />} />
        <Route path="/real-estate-investors" element={<RealEstateScreen />} />
        <Route
          path="/about-our-movement"
          element={<AboutOurMovementScreen />}
        />
        <Route
          path="/initiative-proposal"
          element={<InitiativeProposalScreen />}
        />
        <Route path="/sign-our-petition" element={<FormalPetitionScreen />} />
      </Routes>

      {location.pathname === "/" ? (
        <FooterComponent homepage />
      ) : (
        <FooterComponent />
      )}
    </Fragment>
  );
}

export default App;
