import React from "react";
import "./style.scss";
import { BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
const ArticlePageScreen = () => {
  return (
    <section className="articleScreenSection">
      {/*    Article Heading Sections*/}

      <div className="articleHeadingMainWrapper">
        <h4>BUILDING HOMESCHOOL COMMUNITIES / RESEARCH HIGHLIGHT</h4>

        <h3>
          Navigating and Thriving in the Neighborhood Homeschool Ecosystem
          Through Homeschool Communities
        </h3>
      </div>

      {/*    article dates section*/}

      <div className="articleDatesSectionWrapper">
        <div className="articleDateWrapper">
          <p>Fred C. Pinnegar, Brigham Young University </p>
        </div>
        <div className="articleTimeWrapper">
          <p>Reading Time: 8 minutes</p>
        </div>

        <div className="articleDateWrapper">
          <p className="dateparaStyle">February 17, 2023</p>
        </div>
      </div>

      <div className="articleImageSectionMain">
        <div className="articleImageWrapper">
          <h3 className="articleImageText">
            HELPING LARGER HOMESCHOOL GROUPS FIND THEIR WAY
          </h3>
          <div className="articleImageButtonWrapper">Rent Out a Resource</div>
        </div>

        <p>
          Homeschool Communities is dedicated to supporting homeschooling
          families by providing a revolutionary platform that offers automatic
          access to critical educational resources and amenities, including
          competitive sports programs, orchestra, science labs, and more. These
          resources are available for co-op group bookings in specially designed
          homeschool-friendly neighborhoods, each with its unique offerings. The
          boundaries of these neighborhoods are determined by your homeschool
          district, ensuring that your children can enjoy enriching experiences
          with their neighborhood friends who are also homeschooling. Our
          primary goal is to empower homeschooling families with the resources
          they need to prevent their children from feeling the need to return to
          public schools for certain activities or classes, ultimately keeping
          them within the homeschooling community.
        </p>
        <p>Here are some key points about our platform:</p>
        <ul>
          <li>
            <p>
              Supporting Homeschooling Families: Homeschool Communities is
              dedicated to supporting homeschooling families by granting them
              access to homeschool-friendly neighborhoods that can function as
              educational hubs. These neighborhoods provide a diverse array of
              educational resources, addressing specific needs and preferences.
              We recognize that as students progress, especially during middle
              and high school years, they may desire extracurricular activities
              such as competitive sports, similar to offerings in traditional
              public schools. Our goal is to bridge these gaps by establishing
              communities where comprehensive resources are readily available,
              catering to families who choose to keep their children out of
              public schools.
            </p>
          </li>
          <li>
            <p>
              Airbnb-Style Platform: Homeschool Communities operates as an
              Airbnb-style platform, enabling homeowners to list their spaces
              for use by co-op groups or homeschooling communities. Our aim is
              to connect homeschooling families with suitable hosts who can
              offer conducive learning environments.
            </p>
          </li>
          <li>
            <p>
              Effortless Group Integration: Upon registration, users will be
              automatically assigned to their default neighborhood group,
              ensuring convenient access to local resources and opportunities
              for group learning and socialization. For those who wish, users
              have the option to create their own groups, functioning similarly
              to co-ops, where every parent actively participates in the
              educational process.
            </p>
          </li>
          <li>
            <p>
              Connecting Educators and Learners: It serves as a platform where
              skilled parents and education providers, including subject matter
              tutors and teachers, can showcase their availability and schedule
              their services.
            </p>
          </li>
        </ul>
      </div>

      {/*    article para sections*/}

      <div className="articleParaSectionStart">
        <div className="articleParaOwnSection">
          <h2>Background</h2>
          <p>
            While Homeschooling is becoming increasingly popular, parents have
            discovered that home schooling has its own set of problems, such as
          </p>
          <ul>
            <li>
              <p>Finding co-ops for group learning and socialization</p>
            </li>
            <li>
              <p>
                Finding parents who are willing to sacrifice their time and
                energy to be their children’s greatest teacher, or skilled
                parents willing to share their talents in a group setting,
                because, curiously, critics believe that “homeschooling” is
                often more about parents controlling the education of their
                children, rather than parents actually teaching them academic
                subject matter. Indeed, parents do not always feel qualified by
                knowledge, skill, or temperament to teach their own kids,
                especially after about the 5th grade level, nor are they always
                available, because of work or other commitments, to teach.
              </p>
            </li>
            <li>
              <p>
                Finding Curricular or teaching material and knowing what to
                teach at each grade level and when and how.
              </p>
            </li>
            <li>
              <p>
                Finding Money and Resources, or having and paying for everything
                that is covered by taxes in public schools
              </p>
            </li>
          </ul>
        </div>

        <div className="articleParaOwnSection">
          <h2>
            Plans for future recognition as a legitimate schooling option to
            homeschoolers in middle and high school
          </h2>
          <p>
            It is a commonplace in education that students learn at least as
            much from each other as they do from their teachers—if not more, and
            for years the homeschool movement has been criticized for the lack
            of opportunity for students to socialize. Homeschooled children, in
            the opinion of its skeptics, are isolated and require more
            interaction with peers their same age, as well as other teachers.
          </p>
          <p>
            To address the perceived issues of homeschooling, a variety of
            “hybrid” homeschooling models have emerged in recent years which
            strip away the more pernicious features of public, private,
            parochial, chartered, and even homeschooling, while retaining what
            is best in all of them.
          </p>
          <p>
            There is a long educational history or tradition of parents sending
            their kids to local, neighborhood teachers for specific subject
            matter instruction, particularly in piano and voice or other music
            lessons, as well as students serving apprenticeships with local
            masters to learn crafts and trades. Before the advent of public
            education, boys and girls were often sent to neighborhood
            homeschools to learn their ABC’s, and that tradition is currently
            undergoing a dramatic revival.
          </p>
          <p>
            Similarly, Homeschool Co-ops (a group of families who meet together
            and work cooperatively) have emerged in the past few decades as
            well. Co-ops can be organized around academics, social time, the
            arts, group projects, or a combination of these. Medium (from five
            to about twenty families) to large (more than ten families) co-ops
            are advantageous as there are more families to carry the load.
            However, their greatest challenge is in finding a meeting space.
            Most groups turn to free space in a library, church, or community
            center.
          </p>
          <p>
            The Homeschool Communities platform is being developed to help
            parents of homeschool groups, or co-ops navigate and thrive in the
            neighborhood homeschool ecosystem and economy, and help you
            understand and resolve problems and concerns, especially as older
            children and teenagers start agitating for socialization and
            educational experiences that are characteristic of public middle
            schools and high schools.
          </p>
        </div>

        <div className="articleParaOwnSection">
          <h2>
            Virtual neighborhood home school boundaries enable homeschool groups
            to gather effortlessly around the world
          </h2>
          <p>
            When the LDS Church was organized in April 1830, there was no need
            for an extensive organizational structure, as most Saints could meet
            together in the same place. As individuals and families joined the
            Church in more distant locations, they were organized as separate
            congregations, or “branches.” Then, within a year of the Church’s
            founding, gathering centers were soon established. The Homeschool
            Community project will adopt a similar structure, highlighting the
            Church's modern framework as a model for the “gathering centers.”
          </p>
          <p>
            As the homeschooling movement evolves, the organizational structure
            will stay much the same, but changes are already being anticipated
            to divide large neighborhoods based on the number of users
            registering rather than their distance from one another. These
            adjustments will lead to co-ops of a more manageable size and
            encourage greater participation among local members.
          </p>
          <p>
            When a new user registers on the homeschool community website, a
            membership record gets created and members are assigned to their
            neighborhood homeschool co-op. Members are also welcome to create
            their own co-op, inviting who they want to join.
          </p>
        </div>

        <div className="articleParaOwnSection">
          <h2>
            Homeschool co-ops to do school in safely gated residential
            communities
          </h2>
          <p>
            Along with establishing homeschool co-ops in neighborhoods, plans to
            design and architect homeschool-friendly neighborhoods are being
            made so that you can meet with your group for school once or twice a
            week in a safely gated residential community during school hours
            nearby. Parents have a fiduciary duty to the group to make certain
            that at least two parents are in each room at all times to ensure
            their safety and to protect their children from any type of child
            abuse. It is imperative that parents are always present and on site
            with their own kids.
          </p>
          <p>
            The vision of Homeschool Communities is to stay free of any
            obligation to the state and simply make way for homeschool-friendly
            neighborhoods for co-op groups to do school in. Our mission is to
            commit parents (if they can) to teaching their own children instead
            of dropping them off to be taught by someone else. Homeschool groups
            can also hire volunteers or paid teachers as employees. They can
            find class electives, field trips, apprenticeships, and
            individualized education programs with their co-ops through the
            homeschool community website.
          </p>
        </div>

        <div className="articleParaOwnSection">
          <h2>
            Teachers and Learning Solutions through Homeschool Communities
          </h2>
          <p>
            Homeschoolers discovered long ago that a college degree and
            certification as a teacher don’t necessarily make a person a good
            teacher, and those qualities, absolutely necessary in public
            education, are not essential in the homeschooling classroom.
          </p>
          <p>
            The Homeschool Communities platform allows skilled parents, coaches,
            and/or teachers the option to provide their services to co-ops in
            homeschool-friendly neighborhoods, or communities as volunteers or
            paid employees in our featured “Provider” mode. They will be able to
            create and make their availability known in several categories
            including classes, apprenticeships, individualized education
            programs, sports leagues, field trips, parent workshops, and more.
            They can invite multiple co-ops in neighboring areas to join or
            enroll in their classes or services and waiting lists are created
            when maximum occupancy is achieved. Real time, in-app notifications
            each time a family joins their service is another benefit. These
            options give them the leverage to gain financial growth and
            opportunities using our platform.
          </p>
        </div>

        <div className="articleParaOwnSection">
          <h2>
            Building homes and residential communities for Homeschoolers: an
            untapped market for builders and developers
          </h2>
          <p>
            Although homeschooling was long regarded as a fringe movement with
            dubious educational benefits, it is now seen by many families as an
            increasingly viable alternative.
          </p>
          <p>
            The primary driver behind this change in attitudes was the Covid
            pandemic, which closed public schools and forced parents to make
            their home the classroom. Of course, for some families this was a
            complete disaster, but millions of others found solutions to the
            problems and challenges they encountered. Consequently, right now,
            there is a vast number of families ready to buy or build homes that
            include dedicated learning spaces, and they are ready to locate in
            communities, neighborhoods, and housing developments that
            accommodate the needs and concerns of homeschooling.
          </p>
          <p>
            Homeschoolers are typically affluent and prepared to spend money on
            the education of their children. And there are home builders and
            residential community developers who are prepared to tap this
            emerging market and make money by identifying the specific spatial
            needs of homeschooling clients and designing appropriate
            architectural solutions. In addition, these homes, dedicated to
            homeschooling, need not be isolated, for home schooling is
            increasingly becoming a residential neighborhood or community
            effort, in which the majority of families in a specific geographic
            area participate in a coordinated homeschooling effort, and learning
            spaces (classrooms and resources) are located in numerous homes in
            close proximity.
          </p>
        </div>

        <div className="articleParaOwnSection">
          <h2>
            Homeowners have an opportunity to create an extra revenue stream
            through home sharing
          </h2>
          <p>
            Investors and/or second home buyers need to be willing to dedicate
            their entire home as a subject matter resource (much like Airbnb
            vacation resort communities are solely dedicated to travelers). This
            will provide homeowners with an opportunity to create an extra
            revenue stream through home sharing, who may be passionate about the
            subject matter that their house is designed to cover, and/or who
            share our enthusiasm for supporting parents of homeschooled
            children. Each home will be staged by subject matter before the home
            is sold, or by the homeowner before it is listed on the Homeschool
            Communities website. In many cases, the homebuyer will commit their
            home to the subject matter intended before purchasing the home.
          </p>
        </div>

        <div className="articleParaOwnSection">
          <h2>
            Finding classroom space in homes and teaching staff for neighborhood
            homeschools
          </h2>
          <p>
            It was famously said of Johns Hopkins, that “Johns Hopkins on one
            end of a log in the woods, with a student at the other end, is a
            university.” The statement calls attention to the fact that the most
            important consideration in education is the relationship between
            teacher and student. Nevertheless, there is that log in the forest,
            the context or space where that learning takes place, and in the
            neighborhood homeschool ecosystem, the learning space or classroom
            is in the home, rather than the institutional and often prison-like
            look and feel of public, private, and parochial schools. In the
            homes of homeschoolers, there is typically some designated space
            that serves a characteristic purpose, such as a kitchen, dining room
            or living room, that is repurposed or transformed in minutes or
            seconds into a classroom.
          </p>
          <p>
            This is the ideal, and for most it functions very well. However,
            neighborhood homeschoolers recognize that certain subjects, grade
            levels, or learning experiences, or family circumstances may require
            the use of learning space in someone else’s home, just as the
            learning space and experience in your home may be the most
            appropriate place for the children of other families in the
            neighborhood.
          </p>
          <p>
            The Homeschool Communities platform can help you find and schedule
            classes in the homes of other people, as well as additional school
            resources within a school resort.
          </p>
        </div>

        <div className="articleParaOwnSection">
          <h2>Resource solutions through Homeschool Communities</h2>
          <p>
            Learning resources, such as text and reference books, teaching
            supplies, instruments, computer and electronic equipment, etc., are
            a major expense in any educational context and frightful to the
            homeschooler still paying taxes to support the public school system
            but not having access to public school resources and paying for what
            is needed herself. More and more of those resources are, thankfully,
            available online at little or no cost, but the learning resources
            available in the neighborhood homeschool community are often
            overlooked and typically underutilized. Now, homeschoolers can
            create their own learning libraries and resources to share with
            their co-op, or other homeschoolers in surrounding neighborhoods,
            either for free or for a small rental fee. Neighborhood libraries
            can be accessed from the group’s homepage.
          </p>
        </div>

        <div className="articleParaOwnSection">
          <h2>Rating & Review System offered</h2>
          <p>
            A Rating and Review system throughout the Homeschool Communities
            platform with transparency and professionalism at its core, will
            help guide homeschoolers to make better choices for them in the
            lessons, classes, and extracurricular activities they choose to
            associate with.
          </p>
          <p>
            Homeschooling parents strongly believe that the home provides an
            ideal environment for their children's education, fostering growth
            from kindergarten through graduation and beyond. However, to ensure
            that children can thrive within the homeschooling environment and
            avoid returning to traditional schools, it's crucial to address the
            need for accessible and comprehensive resources.
          </p>
        </div>
      </div>
    </section>
  );
};
export default ArticlePageScreen;
