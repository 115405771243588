import React from "react";

import RealtorsPdf from "../../Assets/PDF/Home-Buyers.pdf";
import Button from "../../utils/Button";

import "../HomeSchoolerScreen/style.scss";

const RealtorsPage = () => {
  return (
    <div className="main_container">
      <h2 className="main_heading">Unlock Your Ideal Home in a Homeschooling Community</h2>

      {/* Introduction */}
      <div className="table_container">
        <h2 className="main_heading main_heading_no_margin">Introduction:</h2>

        <p className="main_para">
          Welcome to Homeschool Communities, where your dream home is more than just a living space; it's a vibrant hub of collaborative learning and community engagement. Explore
          how you can find the perfect home within our innovative homeschooling neighborhoods.
        </p>

        {/* Key Benefits */}
        <h2 className="main_heading">Key Benefits:</h2>

        <h4 className="sub_heading">
          Discover Your Dream Home:&nbsp;
          <span className="main_para">
            Browse through a variety of homes in our homeschooling communities, each designed to cater to your family's unique needs and preferences.
          </span>
        </h4>

        <h4 className="sub_heading">
          Community Amenities:&nbsp;
          <span className="main_para">
            Enjoy access to exceptional community amenities, including sports facilities, auditoriums, science lab rooms, and more, all within a secure, gated environment.
          </span>
        </h4>

        <h4 className="sub_heading">
          Educational Opportunities:&nbsp;
          <span className="main_para">
            Immerse yourself in a dynamic learning environment where homeschooling families come together to create personalized educational experiences.
          </span>
        </h4>

        <h4 className="sub_heading">
          Investment Potential:&nbsp;
          <span className="main_para">
            Explore the option of renting out your home to homeschooling groups and generating additional income while contributing to the community's educational mission.
          </span>
        </h4>

        {/* How It Works */}
        <h2 className="main_heading">How It Works:</h2>

        <h4 className="sub_heading">
          Easy Home Search:&nbsp;
          <span className="main_para">Our user-friendly platform makes it simple to search for homes in your desired homeschooling community, saving you time and effort.</span>
        </h4>

        <h4 className="sub_heading">
          Community Engagement:&nbsp;
          <span className="main_para">
            Become part of a welcoming and inclusive community where families are committed to providing an exceptional educational experience for their children.
          </span>
        </h4>

        <h4 className="sub_heading">
          Investment Opportunities:&nbsp;
          <span className="main_para">Discover how your new home can offer investment potential by connecting with homeschooling groups and co-op communities.</span>
        </h4>

        {/* Contact Info */}
        <h2 className="main_heading">Contact Information:</h2>

        <div className="link_box">
          <p className="main_para link_para">Email:</p>
          <a href="mailto:info@homeschoolcommunities.org" className="homeschool_site_link">
            info@homeschoolcommunities.org
          </a>
        </div>

        <div className="link_box">
          <p className="main_para link_para">Website:</p>
          <a href="https://homeschoolcommunities.org/" target="_blank" rel="noreferrer" className="homeschool_site_link">
            www.homeschoolcommunities.org
          </a>
        </div>
      </div>

      {/* Button */}
      <div className="button_div real_estate_btn">
        <Button btntext="Download as PDF" PDFlink link={RealtorsPdf} />
      </div>
    </div>
  );
};

export default RealtorsPage;
