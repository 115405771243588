import React from 'react';
import LandingPageScreen from "../../Screens/LandingPageScreen";

const LandingPageContainer = () => {
    return(
        <LandingPageScreen />
    )

}

export default LandingPageContainer
