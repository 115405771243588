import React from "react";

import BuildersPdf from "../../Assets/PDF/Builders-and-realtors.pdf";
import Button from "../../utils/Button";

import "./style.scss";
import "../HomeSchoolerScreen/style.scss";

const BuilderPage = () => {
  return (
    <div className="main_container">
      <h2 className="main_heading">Unlock New Opportunities with Homeschool Communities</h2>

      <p className="main_para">
        Are you a visionary in the world of real estate? Do you seek opportunities to create vibrant neighborhoods that blend living spaces with comprehensive educational
        resources? If so, the Homeschool Communities initiative presents an exciting and unique opportunity.
      </p>

      {/* Introduction */}
      <div className="table_container">
        <h2 className="main_heading">Introduction:</h2>

        <p className="main_para">
          Welcome to the Homeschool Communities initiative, where homes become more than just living spaces; they evolve into vibrant hubs of collaborative learning and community
          engagement. This page explores the innovative benefits of homeschool communities, where homes are dedicated to enriching education.
        </p>

        {/* Key Benefits */}
        <h2 className="main_heading">Key Benefits:</h2>

        <h4 className="sub_heading">
          Dynamic Learning Environments:&nbsp;
          <span className="main_para">
            Within this community, homes transform into dedicated learning spaces, each tailored to specific subjects. These spaces are available for homeschool groups during
            designated hours, fostering a dynamic learning environment.
          </span>
        </h4>

        <h4 className="sub_heading">
          Flexible Education:&nbsp;
          <span className="main_para">
            Customize education to individual needs and preferences, offering flexibility and choice. Homeschool groups have the freedom to design their curriculum and learning
            approach.
          </span>
        </h4>

        <h4 className="sub_heading">
          Comprehensive Recreational Facilities:&nbsp;
          <span className="main_para">
            Immerse yourself in a community where recreational facilities rival those of public schools. Homeschool-friendly neighborhoods offer access to extensive sports
            amenities, including track and field, baseball, and soccer fields centrally located within the community. These versatile spaces not only encourage physical activity
            but also provide opportunities for structured competitive sports programs and memorable family gatherings.
          </span>
        </h4>

        <h4 className="sub_heading">
          Safe and Gated:&nbsp;
          <span className="main_para">The entire community is gated, ensuring a secure environment for residents and homeschool groups.</span>
        </h4>

        <h4 className="sub_heading">
          Income Opportunities:&nbsp;
          <span className="main_para">
            Homeowners have a unique opportunity to rent out their homes to homeschool groups, generating additional income. Each room can be thoughtfully staged to cater to the
            diverse needs of co-op groups while adhering to important safety regulations. For instance, when children are present in a room, it's essential to have at least two
            adults supervising. While considering this requirement, homeowners can customize the environment in each room, making them inviting and conducive to various age groups
            and subjects. This appeals to like-minded individuals passionate about sharing their expertise and generating income while ensuring compliance with co-op guidelines.
          </span>
        </h4>

        {/* Community-Based Homeschool Districts */}
        <h2 className="main_heading">Why You Should Consider Homeschool Communities:</h2>

        <h4 className="sub_heading">
          Homebuilders:&nbsp;
          <span className="main_para">
            If you're a developer with a vision for creating vibrant neighborhoods and comprehensive educational resources centered around homeschooling, this concept presents an
            exciting opportunity.
          </span>
        </h4>

        <h4 className="sub_heading">
          Realtors:&nbsp;
          <span className="main_para">
            Realtors play a pivotal role in helping clients find their ideal homes. By introducing homeschool communities, you can offer a truly unique selling point that sets you
            apart in the real estate market.
          </span>
        </h4>

        {/* Conclusion */}
        <h2 className="main_heading">Conclusion:</h2>
        <p className="main_para">
          Consider how homeschool communities redefine the concept of living spaces and offer a new dimension to real estate. To learn more, visit our website at&nbsp;
          <a href="https://homeschoolcommunities.org/" target="_blank" rel="noreferrer" className="homeschool_link">
            homeschoolcommunities.org
          </a>
          &nbsp;to download our comprehensive guide.
        </p>

        {/* Contact Information */}
        <h2 className="main_heading main_heading_no_margin">Contact Information:</h2>

        <div className="link_box">
          <p className="main_para link_para">Email:</p>
          <a href="mailto:info@homeschoolcommunities.org" className="homeschool_site_link">
            info@homeschoolcommunities.org
          </a>
        </div>
        <p className="main_para contact_para">
          We appreciate your interest and look forward to assisting you in exploring the possibilities that Homeschool Communities can offer in reshaping the future of education
          and real estate.
        </p>
      </div>

      {/* Button */}
      <div className="button_div real_estate_btn ">
        <Button btntext="Download as PDF" PDFlink link={BuildersPdf} />
      </div>
    </div>
  );
};

export default BuilderPage;
