import React from "react";

import HomeSchoolersPdf from "../../Assets/PDF/Homeschooling-Families.pdf";
import Button from "../../utils/Button";

import "./style.scss";

const HomeSchoolerPage = () => {
  return (
    <div className="main_container">
      <h2 className="main_heading">Homeschool Communities Reference Guide</h2>

      <h3 className="italic_heading">Unlock New Opportunities with Homeschool Communities</h3>

      <p className="main_para">
        Welcome to the Homeschool Communities initiative, where homes become hubs of collaborative learning and community engagement. This reference guide provides an in-depth look
        at the innovative benefits of homeschool communities and how they are reshaping the future of education.
      </p>

      {/* Table Content */}
      <div className="table_container">
        <h2 className="main_heading">Table of Contents</h2>
        <div className="table_content">
          <p className="main_para no_margin_text">1.</p>
          <p className="main_para no_margin_text">Introduction</p>
        </div>
        <div className="table_content">
          <p className="main_para no_margin_text">2.</p>
          <p className="main_para no_margin_text">Key Benefits</p>
        </div>
        <div className="table_content">
          <p className="main_para no_margin_text">3.</p>
          <p className="main_para no_margin_text">How It Works</p>
        </div>
        <div className="table_content">
          <p className="main_para no_margin_text">4.</p>
          <p className="main_para no_margin_text"> Community-Based Homeschool Districts</p>
        </div>
        <div className="table_content">
          <p className="main_para no_margin_text">5.</p>
          <p className="main_para no_margin_text">Getting Involved</p>
        </div>
        <div className="table_content">
          <p className="main_para no_margin_text">6.</p>
          <p className="main_para no_margin_text">Contact Information</p>
        </div>

        {/* Introduction */}
        <div className="table_content intro_content">
          <h2 className="main_heading">Introduction</h2>
        </div>
        <p className="main_para">
          Discover a groundbreaking concept where homes become more than living spaces – they become hubs of collaborative learning and community engagement. This one-page guide
          explores the innovative benefits of homeschool communities, where homes are dedicated to enriching education.
        </p>

        {/* Key Benefits */}
        <div className="table_content intro_content">
          <h2 className="main_heading">Key Benefits</h2>
        </div>
        <h4 className="sub_heading">
          Dynamic Learning Environments:&nbsp;
          <span className="main_para">
            Homes within this community serve as dedicated learning spaces, each transformed into subject-specific classrooms. These spaces are available for homeschool groups
            during specific hours, creating a dynamic learning environment.
          </span>
        </h4>

        <h4 className="sub_heading">
          Flexible Education:&nbsp;
          <span className="main_para">
            Tailor education to individual needs and preferences, offering flexibility and choice. Homeschool groups have the freedom to design their curriculum and learning
            approach.
          </span>
        </h4>

        <h4 className="sub_heading">
          Comprehensive Recreational Facilities:&nbsp;
          <span className="main_para">
            Immerse yourself in a community where recreational facilities rival those of public schools. Homeschool-friendly neighborhoods offer access to extensive sports
            amenities, including track and field, baseball, and soccer fields centrally located within the community. These versatile spaces not only encourage physical activity
            but also provide opportunities for structured competitive sports programs and memorable family gatherings.
          </span>
        </h4>

        <h4 className="sub_heading">
          Safe and Gated:&nbsp;
          <span className="main_para">The entire community is gated, ensuring a secure environment for residents and homeschool groups.</span>
        </h4>

        <h4 className="sub_heading">
          Income Opportunities:&nbsp;
          <span className="main_para">
            Homeowners have the unique opportunity to rent out their homes to homeschool groups for income. Each room can be thoughtfully staged to cater to the diverse needs of
            co-op groups while adhering to important safety regulations. For instance, when children are present in a room, it's essential to have at least two adults supervising.
            While considering this requirement, homeowners can customize the environment in each room, making them inviting and conducive to various age groups and subjects. For
            example, rooms can be set up with educational materials, flexible seating arrangements, and age-appropriate resources, providing a versatile space for homeschooling
            families. This appeals to like-minded individuals passionate about sharing their expertise and generating income while ensuring compliance with co-op guidelines.
          </span>
        </h4>

        {/* How It Works */}
        <div className="table_content intro_content">
          <h2 className="main_heading">How It Works</h2>
        </div>
        <p className="main_para no_margin_text">
          Imagine a world where finding or creating homeschool co-ops is no longer a challenge. With our initiative, we're making this a reality! When you register with us, you're
          not just signing up; you're becoming a part of your very own neighborhood homeschool group. It's like joining a club where all your neighbors who homeschool are
          automatically your fellow members. You won't need to spend hours searching or posting to strangers, hoping for responses.
        </p>
        <p className="main_para">
          But here's where it gets even more exciting: if you have a vision for your own group or if you'd like to team up with others nearby, it's as simple as clicking a button.
          Creating or merging groups has never been easier. You have the power to shape your homeschooling experience without the usual hassle.
        </p>

        {/* Community-Based Homeschool Districts */}
        <div className="table_content intro_content">
          <h2 className="main_heading">Community-Based Homeschool Districts</h2>
        </div>
        <p className="main_para">
          We're dividing neighborhoods into districts similar to school districts. Similar to LDS churches Wards and Stakes where you can see all your wards in the boundaries of
          the stake center and all the neighbors in your ward on LDS tools, homeschool communities will offer the same approach. And what's even cooler: As soon as you register,
          you're automatically placed in your neighborhood group, however, you can create your own group if you wish with the click of a button and all of our features to create
          and build your own successful group will be at your fingertips to streamline the process. You can invite and merge with other groups as well. You click on a neighbor and
          invite them or merge groups, etc. SUPER EASY.
        </p>

        <h2 className="main_heading">Why Community-Based Homeschool Districts?</h2>

        <h4 className="sub_heading">
          Community Engagement:&nbsp;
          <span className="main_para foster_para">Foster a sense of belonging and community for homeschooling families.</span>
        </h4>

        <h4 className="sub_heading">
          Resource Sharing:&nbsp;
          <span className="main_para">Facilitate resource sharing among homeschooling families.</span>
        </h4>

        <h4 className="sub_heading">
          Flexibility:&nbsp;
          <span className="main_para">Seamlessly enroll homeschoolers in their neighborhood homeschool group upon registration.</span>
        </h4>

        {/* Getting Involved */}
        <div className="table_content intro_content">
          <h2 className="main_heading">Getting Involved</h2>
        </div>
        <p className="main_para">
          Realtors, builders, homebuyers, teachers, co-ops, and passionate individuals—everyone has a role to play in shaping the future of education. Visit our website at&nbsp;
          <a href="https://homeschoolcommunities.org/" style={{ color: "#0062e3" }} target="_blank" rel="noreferrer">
            https://homeschoolcommunities.org
          </a>
          &nbsp;to learn more about how Homeschool Communities are redefining education and creating learning spaces in your neighborhood.
        </p>

        {/* Contact Information */}
        <div className="table_content intro_content">
          <h2 className="main_heading">Contact Information</h2>
        </div>

        <p className="main_para lisa_text">Lisa</p>

        <div className="link_box">
          <p className="main_para">Email:</p>
          <a href="mailto:info@homeschoolcommunities.org" className="homeschool_site_link">
            info@homeschoolcommunities.org
          </a>
        </div>

        <div className="link_box">
          <p className="main_para link_para">Website:</p>
          <a href="https://homeschoolcommunities.org/" target="_blank" rel="noreferrer" className="homeschool_site_link">
            www.homeschoolcommunities.org
          </a>
        </div>
      </div>

      {/* Button */}
      <div className="button_div">
        <Button btntext="Download as PDF" PDFlink link={HomeSchoolersPdf} />
      </div>
    </div>
  );
};

export default HomeSchoolerPage;
